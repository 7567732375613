import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout, { Wide } from "../components/layout"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { TwitterShareSmall, SocialLarge, FacebookShareSmall } from "./social"
import { Link } from "gatsby"
import { Title } from "./title"
import url from '../utils/url'

const shortcodes = { Link }

const PageHeader = ({page, pageUrl}) => {
  return (
    <div className="px-4 w-full max-w-xl m-auto pb-4 md:pt-6">
      <Title>{page.frontmatter.title}</Title>
      {page.frontmatter.social && <p className="pt-2 flex items-center">
        <TwitterShareSmall title={page.frontmatter.title} url={pageUrl} />
        <FacebookShareSmall url={pageUrl} />
      </p>}
    </div>
  );
}

export default function Page({ data, location }) {
  const page = data.mdx
  const pageTitle = `${page.frontmatter.title} – Vonk`;
  const pageUrl = url(location, data.site.siteMetadata.siteUrl)
  return (
    <Layout>
      <SEO
        title={pageTitle} 
        path={location.pathname}
      />
      <Wide>
        <PageHeader page={page} pageUrl={pageUrl} />
        <MDXProvider components={shortcodes}>
          <div className="px-4 prose max-w-xl prose-lg m-auto">
            <MDXRenderer>{page.body}</MDXRenderer>
          </div>
        </MDXProvider>
        {page.frontmatter.social &&<div className="px-4 max-w-xl m-auto text-gray-600">
          <SocialLarge title={page.frontmatter.title} url={pageUrl}/>
        </div>}
      </Wide>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        social
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
